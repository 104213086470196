import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { UserContext } from "../contexts/Contexts";
import { MultiSelect, Select } from '@mantine/core';

function ProcessesPage() {

    const [processes, setProcesses] = useState([]);
    const user = useContext(UserContext);

    const [states, setStates] = useState([]);
    const[orderBy, setOrderBy] = useState(null);

    function isStateFiltered(value) {
        if (states.length === 0) return true; 
    
        return states.some(loaded => loaded.toLowerCase() == value.toLowerCase());
    }

    const fetchProcesses = async() => {
        return await fetch(process.env.REACT_APP_BACKEND_URL + "/mentorships/processes", {
            credentials: "include"
        });
    }

    useEffect(() => {
        fetchProcesses().then(response => {
            if (response.ok) {
                response.json().then(data => {
                    setProcesses(data);
                })
            } else {
                toast.error("We failed to fetch mentorship processes");
            }
        }).catch(error => {
            toast.error("We failed to fetch mentorship processes - " + error);
        })
    }, []);


    if (user == null) return;

    const filteredProcesses = processes.filter(process => isStateFiltered(process.status));

    const sortedProcesses = [...filteredProcesses].sort((a, b) => {
        if (orderBy === "Most Payments") {
          return b.paymentData.length - a.paymentData.length;
        } else if (orderBy === "Latest Payment") {
          return new Date(b.fullPaymentCompletedAt) - new Date(a.fullPaymentCompletedAt);
        }
        return 0; // Default order if orderBy doesn't match
      });

    return <div className="container">

            <div className="flex items-center gap-6 w-full flex-wrap">
                <MultiSelect
                    label="Filter by state"
                    placeholder="Pick value"
                    data={['In Process', 'Completed', 'Archived']}
                    className="max-w-[450px] w-full"
                    onChange={value => {
                        setStates(value);
                    }}
                />

                <Select
                    label="Order By"
                    placeholder="Pick value"
                    data={['Most Payments', 'Latest Payment']}
                    className="max-w-[450px]"
                    onChange={value => {
                        setOrderBy(value);
                    }}
                />
            </div>

            <div className="flex flex-col gap-10 mt-10">
                {sortedProcesses.map(process => {

                    return (
                    
                    <div className="border-[white] border-[2px] p-3 rounded-[12px] w-[100%] transition-all" key={process.createdAt}>
                        <div className="flex flex-wrap justify-between">
                            <div className="text-white">
                       
                                <div className="flex gap-2">
                                    <i className="fa-solid fa-sitemap mt-[2px] text-black"></i>
                                    <div>Art Club: <div className="text-[#a1ff4f] underline inline-block">{process.mentorshipName}</div></div>
                                </div>

                                <div className="flex gap-2">
                                    <i className="fa-solid fa-id-card-clip mt-[5px] text-black"></i>
                                    <div>Art Club ID: <div className="text-[#a1ff4f] underline inline-block">{process.artClubId}</div></div>
                                </div>

                                <div className="flex items-center gap-5 flex-wrap">

                                    <div className="flex gap-2">
                                        <i className="fa-solid fa-user-tie mt-[2px] text-black"></i>
                                        <div>User: <div className="text-[#a1ff4f] underline inline-block">{process.userName}</div></div>
                                    </div>

                                    <div className="flex gap-2">
                                        <i className="fa-solid fa-envelope mt-[4px] text-black"></i>
                                        <div>Email: <div className="text-[#a1ff4f] underline inline-block">{process.email}</div></div>
                                    </div>

                                    <div className="flex gap-2">
                                        <i className="fa-regular fa-circle mt-[5px] text-black"></i>
                                        <div className="text-white">Status: <div className="text-[#a1ff4f] underline inline-block">{process.status}</div></div>
                                    </div>

                                    <div className="flex gap-2">
                                        <i className="fa-brands fa-discord mt-[5px] text-black"></i>
                                        <div className="text-white">Discord ID: <div className="text-[#a1ff4f] underline inline-block">{process.discordId}</div></div>
                                    </div>
                                </div>

                

                                <br />
                                <div className="flex gap-2">
                                    <i className="fa-solid fa-calendar-days mt-[2px] text-black"></i>
                                    <div className="text-white">Created at: <div className="text-[#a1ff4f] underline inline-block">{formatDate(new Date(process.createdAt))}</div></div>
                                </div>

                                <div className="flex gap-2">
                                    <i className="fa-regular fa-calendar-days mt-[2px] text-black"></i>
                                    <div className="text-white">Last payment completed: <div className="text-[#a1ff4f] underline inline-block">{process.fullPaymentCompletedAt != -1 ? formatDate(new Date(process.fullPaymentCompletedAt)) : "No"}</div></div>
                                </div>

                                <div className="flex gap-2">
                                    <i className="fa-solid fa-maximize mt-[2px] text-black"></i>
                                    <div className="text-white">Total payments completed: <div className="text-[#a1ff4f] underline inline-block">{process.paymentData.length}</div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                })}
            </div>
    </div>
}

function formatDate(date) {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };
    return date.toLocaleString('en-US', options);
}

export default ProcessesPage;